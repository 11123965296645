<template>
  <div>
    <div class="height-45 s_flex_bian_c width100">
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 1 ? "s_bf054 tw-text-white":"s_bf"' @click='tabClick(1)'>扫码界面</div>
      <div class="height-45 s_flex_center width50 fz-14" :class='active == 2 ? "s_bf054 tw-text-white":"s_bf"' @click='tabClick(2)'>物流码明细</div>
    </div>
    <div class="m-t-10" v-if='active == 1'>
      <van-form @submit="onSubmit">
        <van-field v-model="orderData.out_warehousing_sn" name="out_warehousing_sn" label="出库单号" placeholder="出库单号" readonly></van-field>
        <van-field v-model="orderData.create_role_name" readonly name="create_role_name" label="收货人" placeholder="请选择收货人"/>
        <van-field v-model="orderData.warehouse_position_name" readonly name="warehouse_position_name" label="出库仓库" placeholder="请选择入库仓库"/>
        <van-field v-model="orderData.logistics_data" readonly name="logistics_data" label="物流信息" placeholder="物流信息"/>
        <div class="m-t-10 s_bf p-l-15 p-r-15 p-t-15">
          <div class="s_flex_bian_c p-b-15">
            <img class="width-80 height-80 tw-rounded-sm" :src="info.oss_domain+goodsData.product_image" alt="">
            <div class="width72">
              <p class="fz-15 s_omit">{{goodsData.product_name}}</p>
              <p class="tw-text-gray-400 m-t-5">规格：{{goodsData.product_sku_name || '单规格'}}</p>
              <p class="tw-text-gray-400">编号：{{goodsData.product_sn}}</p>
            </div>
          </div>
          <van-divider :style="{ margin: '0' }" />
        </div>
        <van-field name="radio" label="扫码类型">
          <template #input>
            <van-radio-group v-model="form.scan_type" direction="horizontal" checked-color='#5B9DFF' @click='typeCheck = true'>
              <van-radio :name="1">扫码出库</van-radio>
              <van-radio :name="2">扫码回退</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="form.code_sn" name="code_sn" label="二维码" ref="codeEle" autocomplete="off" placeholder="请输入二维码" required :rules="[{ required: true, message: '请输入二维码' }]">
          <template #button>
            <van-button style="width:66px" block color='#5B9DFF' size="small" type="primary" native-type="submit">{{form.scan_type == 1 ? '出库':'回退'}}</van-button>
          </template>
        </van-field>
        <van-field v-model="form.scan_num" readonly name="scan_num" label="扫码数量" placeholder="扫码数量" v-if='showResult'/>
        <van-field v-model="form.real_stock_num" readonly name="real_stock_num" label="商品数量" placeholder="商品数量" v-if='showResult'/>
        <div class="fz-14 p-15" v-if='showResult && !typeCheck'>
          <p class="width100">
            <span v-if='form.scan_type == 1'>扫码结果：{{resultCode == 0 ? '出库成功！':'出库失败！'}}</span>
            <span v-else>扫码结果：{{resultCode == 0 ? '回退成功！':'回退失败！'}}</span>
          </p>
          <p class="width100 m-t-15" v-if='resultCode != 0'>{{resultMsg}}</p>
        </div>
      </van-form>
    </div>
    <div class="m-t-10" v-if='active == 2'>
      <van-search
        v-model="search.keyword"
        show-action
        placeholder="请输入物流码进行搜索"
      >
        <template #action>
          <div class="height-34 s_flex_center s_bf054 width-55 tw-text-white tw-rounded-sm" @click="onSearch">搜索</div>
        </template>
      </van-search>
      <div class="p-15" v-if='list.length'>
          <div class="s_flex_bian_c s_bf5 height-40 tw-border tw-border-gray-200 tw-border-solid tw-bg-gray-300 tw-bg-opacity-30">
            <p class="width33 s_flex_center">物流码</p>
            <p class="width33 s_flex_center">码类型</p>
            <p class="width33 s_flex_center">商品数量</p>
          </div>
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model:loading="loading"
              :finished="finished"
              :immediate-check='false'
              finished-text="我也是有底线的~"
              @load="onLoad"
            >
              <div class="s_flex_bian_c height-40 tw-border tw-border-gray-200 tw-border-solid tw-border-t-0 s_bf" v-for="(item,index) in list" :key='index'>
                <p class="tw-text-center width33">{{item.code_sn}}</p>
                <p class="tw-text-center width33">{{item.code_type_text}}</p>
                <p class="tw-text-center width33">{{item.code_num}}</p>
              </div>
            </van-list>
          </van-pull-refresh>
      </div>
      <van-empty v-else :image="require('@/assets/image/empty.png')" description="暂无物流码" />
    </div>
    <div class="tw-fixed tw-left-0 tw-bottom-0 s_flex_center width100 p-15">
      <van-button block round color="linear-gradient(to right, #53A1FF, #68D0FF)" @click='confirmOut'>确定出库</van-button>
    </div>
    <audioPlay ref="audios" />
  </div>
</template>

<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { Toast,Dialog } from 'vant';
  import { iscode } from '@/utils/app.js';
	import { checkOutWarehouse,setOutWarehouse,getOutWarehouseMore,getOutWarehouseCode,setOutWarehouseScan } from '@/api/outWarehouse.js';
  import { SplitArray } from "@/utils/util";
  import store from '@/store'
import { useWatchScan } from "@/hooks/scanWatch.js";

  let defForm = {
    out_warehousing_sn: '',
    code_sn: '',
    out_warehouse_product_id: '',
    scan_type: 1,
    scan_num:0,
    real_stock_num:0
  };
  // 初始化默认筛选项数值
  let defSearch = {
    page: 1,
    limit: 20,
    keyword: ""
  };
  export default {
    setup() {
      const form = ref(defForm);
      const search = ref({ ...defSearch });
      const state = reactive({
        active: 1,
        list:[],
        orderData:{},
        goodsData:{},
        isDisabled:false, //是否禁用某些选项
        showResult:false, // 是否显示扫码结果
        resultCode:'', //扫码结果code
        resultMsg:'', // 扫码结果提示语
        info:store.state.user.info,
        loading: false, // 是否处于加载状态，加载过程中不触发 load 事件
        finished: false, // 是否已加载完成，加载完成后不再触发 load 事件
        refreshing: false, // 是否处于加载中状态
        typeCheck:false, //扫码类型切换，暂时隐藏入库成功/回退成功
      })
      const $route = inject("$route");
      const $router = inject("$router");
      //获取详情
      const getMore = async() =>{
        let result = await getOutWarehouseMore($route.query.sn).then(res => res.data).catch(error => error)
        if (iscode(result)) {
          result.data.logistics_data = result.data.logistics_name+'-'+result.data.logistics_code
					state.orderData = result.data;
          state.goodsData = result.data.product[0]
				}else{
          Toast.fail(result.msg);
				}
      }

      // tab点击
      const tabClick = (e) =>{
        state.active = e;
        if(e == 2){
          onRefresh()
        }
      }
      //获取明细
      const getList = async() =>{
        if (state.finished && state.loading) return
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
        let result = await getOutWarehouseCode({ ...search.value,sn:$route.query.sn}).then(res => res.data).catch(error => error)
        Toast.clear();
				if (iscode(result)) {
					var listData = result.data || [];
					var list = SplitArray(listData, state.list);
					var finished = listData.length < search.value.limit;
					state.list = list;
					state.finished = finished;
          state.loading = false;
				}else{
          Toast.fail(result.msg);
				}
      }
      // 上拉加载
      const onLoad = () => {
        search.value.page =  search.value.page+1
			  getList();
      };
      //下拉刷新
      const onRefresh = () => {
        state.finished = false;
        state.list = [];
        search.value = { ...search.value, page: 1 };
        getList();
      };
      const onSearch = (e) =>{
        onRefresh();
      }
      //扫码
      const onSubmit = async(values) => {
        if(!form.value.code_sn){
          Toast.fail('请输入二维码');
          return
        }
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
        let result = await setOutWarehouseScan({...form.value,out_warehousing_sn:state.orderData.out_warehousing_sn,out_warehouse_product_id:state.goodsData.out_warehouse_product_id}).then(res => res.data).catch(error => error)
        Toast.clear();
        if (iscode(result)) {
          Toast.success(result.msg);
          form.value.real_stock_num = result.data.real_stock_num;
          form.value.scan_num = result.data.scan_num
				}else{
          Toast.fail(result.msg);
				}
        state.resultCode = result.code
        state.resultMsg = result.msg
        state.showResult = true
        form.value.code_sn = ''
        state.typeCheck = false
      };
      //提交检查
      const confirmOut = (values) => {
        Dialog.confirm({
          title: '确定出货？',
          confirmButtonColor:'#5B9DFF',
        }).then(async() => {
          let result = await checkOutWarehouse(state.orderData.out_warehousing_sn).then(res => res.data).catch(error => error)
          if (iscode(result)) {
            outWarehouseSubmit(result.data.is_all);
          }else{
            Toast.fail(result.msg);
          }
        }).catch(() => {})

      };
      //出库
      const audios=ref('')
      const outWarehouseSubmit = async(out_type) =>{
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration:0
        });
        let result = await setOutWarehouse({
          out_warehousing_sn:state.orderData.out_warehousing_sn,
          out_type:out_type,
          delivery_type:state.orderData.delivery_type,
          logistics_name:state.orderData.logistics_name,
          logistics_code:state.orderData.logistics_code,
        }).then(res => res.data).catch(error => error);
        Toast.clear();
        if (iscode(result)) {
          Dialog.confirm({
            title: '出货成功',
            confirmButtonText:'继续发货',
            confirmButtonColor:'#5B9DFF',
          }).then(async() => {
            $router.go(-1);
          }).catch(() => {
            $router.go(-2)
          })
				}else{
          audios.value.error()
          Toast.fail(result.msg);
				}
      }
      onMounted(() => {
        getMore();
      })
    const  codeEle=ref('')
    useWatchScan().openWatch((status) => {
      if (!status && !form.value.code_sn) {
        codeEle.value.focus();
      }
    });
      return {
        ...toRefs(state),
        form,
        search,
        onSubmit,
        onSearch,
        tabClick,
        onRefresh,
        onLoad,
        audios,
        codeEle,
        confirmOut
      };
    }
  };
</script>
